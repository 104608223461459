import React, { useCallback, useEffect, useState } from "react";
// @Mui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Grid,
  Avatar,
  Box,
  Typography,
} from "@mui/material";
import Iconify from "../Iconify";
//react-router-dom
import { useNavigate, useParams } from "react-router-dom";
import { encryptRequest } from "src/__apis__/apps";
import {
  offerClickRequest,
  offerWallClickRequest,
} from "src/__apis__/offerWall";

// ------------------------------------------------------------------------------------------

function OfferPopUp({
  isTriggered,
  closeHandler,
  offer,
  appData,
  special_payout_percentage,
}) {
  // const [queryParams, setQueryParams] = useState({});
  const { publisher_id, uid, app_id } = useParams();
  const navigate = useNavigate();

  const [finalPrice, setFinalPrice] = useState();
  const [finalEventPrice, setFinalEventPrice] = useState(0);
  const [encryptedData, setEncryptedData] = useState();

  const {
    name,
    description,
    preview_avatar,
    payout,
    publishers_payout,
    currency,
    cta_url,
    source,
    platforms,
    events,
  } = offer;

  const encryptData = useCallback(async () => {
    await encryptRequest(app_id, uid, publisher_id)
      .then((response) => {
        setEncryptedData(response);
      })
      .catch((error) => {
        console.log("Failed encrypt data", error);
      });
  }, [app_id, uid, publisher_id]);

  const handleShowPlatforms = useCallback(() => {
    if (Array.isArray(platforms)) {
      return platforms?.map((platform, index) => (
        <Typography key={index} sx={{ mr: 1, color: "gray" }}>
          {platform.name}
        </Typography>
      ));
    } else {
      return <Typography>{platforms}</Typography>;
    }
  }, [offer]);

  const generateUpdatedUrl = useCallback(() => {
    let updatedUrl = cta_url;
    const publisherAppId = `${encryptedData?.data}`;

    if (source === "adgate") {
      // Replace the s1 parameter in the cta_url
      updatedUrl = updatedUrl.replace(/(s1=)([^&]*)/, `$1${publisherAppId}`);
    } else if (source === "offertoro") {
      // Replace the [USER_ID] placeholder in the cta_url
      updatedUrl = updatedUrl.replace("[USER_ID]", publisherAppId);
    }

    return updatedUrl;

    // let updatedUrl = cta_url;
    // const publisherAppId = `${publisher_id}-${uid}-${app_id}`;

    // if (source === "adgate") {
    //   // Replace the s1 parameter in the cta_url
    //   updatedUrl = updatedUrl.replace(/(s1=)([^&]*)/, `$1${publisherAppId}`);
    // } else if (source === "offertoro") {
    //   // Replace the [USER_ID] placeholder in the cta_url
    //   updatedUrl = updatedUrl.replace("[USER_ID]", publisherAppId);
    // }

    // return updatedUrl;
  }, [cta_url, source, encryptData, encryptedData]);

  let totalEventPayout = 0;

  useEffect(() => {
    if (special_payout_percentage === 0) {
      setFinalPrice(publishers_payout * appData[0]?.currency_multiplier);
      if (events) {
        events.map((event) => {
          totalEventPayout +=
            event.publishers_payout * appData[0]?.currency_multiplier;
        });
      }
      setFinalEventPrice(totalEventPayout);
    } else {
      setFinalPrice(
        payout *
          (special_payout_percentage / 100) *
          appData[0]?.currency_multiplier
      );
      if (events) {
        events.map((event) => {
          totalEventPayout +=
            event.payout *
            (special_payout_percentage / 100) *
            appData[0]?.currency_multiplier;
        });
      }
      setFinalEventPrice(totalEventPayout);
    }
  }, [publishers_payout, appData, events]);

  useEffect(() => {
    encryptData();
  }, [app_id, uid]);

  const offerClick = useCallback(
    async (link) => {
      offerClickRequest(publisher_id)
        .then((response) => {
          console.log("offer click", response);
        })
        .catch((error) => {
          console.log("error click offer", error);
        });
      navigate("/redirect", { state: { link: link } });
    },
    [publisher_id]
  );

  return (
    <Dialog open={isTriggered} onClose={closeHandler} fullWidth>
      {/* <DialogTitle>Offer</DialogTitle> */}
      <DialogContent
        sx={{
          scrollbarWidth: "none", // For Firefox
          "&::-webkit-scrollbar": { display: "none" }, // For Chrome, Safari, and Opera
        }}
      >
        <Grid container rowGap={3}>
          <Grid item xs={12} md={4}>
            <Avatar
              variant="rounded"
              src={preview_avatar}
              sx={{ width: 150, height: 150 }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography
              sx={{
                whiteSpace: "normal", // Allows text to wrap
                overflow: "hidden",
                width: "100%", // Adjusts width to use 100% of the available space in the stack
              }}
              variant="h6"
            >
              {name}
            </Typography>
            <Box
              sx={{
                display: "flex",
              }}
            >
              {handleShowPlatforms()}
            </Box>
            <Typography
              sx={{
                color: "primary.main",
                backgroundColor: "#F5F5F5",
                p: 1,
                borderRadius: 1,
                border: "1px solid transparent", // Set a transparent border initially
                display: "inline-block", // Make the element inline-block to wrap around the text
                cursor: "default",
              }}
            >
              {publishers_payout && payout
                ? `${finalPrice?.toFixed(2)} ${appData[0]?.currency_name}`
                : `${finalEventPrice?.toFixed(2)} ${appData[0]?.currency_name}`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              bgcolor: "#F5F5F5",
              borderRadius: 2,
              p: 2,
              cursor: "default",
            }}
          >
            <Typography variant="h5" sx={{ mb: 1.5 }}>
              Description
            </Typography>
            <Typography
              sx={{
                width: "100%", // Ensure the typography component uses the full width available
              }}
            >
              {description}
            </Typography>
          </Grid>
          {events && events.length != 0 && (
            <Grid
              item
              xs={12}
              sx={{
                bgcolor: "#F5F5F5",
                borderRadius: 2,
                p: 2,
                cursor: "default",
              }}
            >
              <Typography variant="h5" sx={{ mb: 1.5 }}>
                Tasks
              </Typography>
              <Grid container rowSpacing={3}>
                {events && events.length != 0 ? (
                  events.map((event) => (
                    <Grid item xs={12} sx={{ pl: 1 }}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Iconify
                          icon="lets-icons:done-ring-round-fill"
                          sx={{ color: "primary.main", mr: 1 }}
                        />
                        <Box>
                          <Typography variant="body1" sx={{ mb: 1 }}>
                            {event.name}
                          </Typography>
                          <Typography variant="body1" fontWeight="bolder">
                            {special_payout_percentage === 0
                              ? `${(
                                  event.publishers_payout *
                                  appData[0]?.currency_multiplier
                                )?.toFixed(2)} ${appData[0]?.currency_name}`
                              : `${(
                                  event.payout *
                                  (special_payout_percentage / 100) *
                                  appData[0]?.currency_multiplier
                                )?.toFixed(2)} ${appData[0]?.currency_name}`}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ))
                ) : (
                  <Box />
                )}
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              LinkComponent={Link}
              // href={generateUpdatedUrl()}
              onClick={() => offerClick(generateUpdatedUrl())}
              target="__blank"
              sx={{ mt: 1, width: "100%" }}
              variant="contained"
            >
              {publishers_payout && payout
                ? `${finalPrice?.toFixed(2)} ${appData[0]?.currency_name}`
                : `start`}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {/* <Button
          variant="contained"
        >
          Ok
        </Button> */}
      </DialogActions>
    </Dialog>
  );
}

export default OfferPopUp;
