import axios from "axios";

export const fetchFusionSurveyRequest = async (
  memberId,
  respondentId,
  respondentLocalization
) => {
  const url = "https://fusionapi.spectrumsurveys.com/surveys/fusionMatch";
  const params = {
    memberId: memberId,
    respondentId: respondentId,
    respondentLocalization: respondentLocalization,
  };

  const headers = {
    "access-token": `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbXAiOjI5MCwiaWF0IjoxNjc3NjE1NTkxfQ.LlizNhqNzvUFOGPcVBDf-pJPOdAnl9gZIy9P5j5rUrc`, // Include the access token here
  };

  const response = await axios.get(url, { params, headers });
  return response.data;
};

export const countryToLang = {
  AF: "fa_AF", // Afghanistan
  AL: "sq_AL", // Albania
  DZ: "ar_DZ", // Algeria
  AR: "es_AR", // Argentina
  AM: "hy_AM", // Armenia
  AU: "en_AU", // Australia
  AT: "de_AT", // Austria
  AZ: "az_AZ", // Azerbaijan
  BH: "ar_BH", // Bahrain
  BD: "bn_BD", // Bangladesh
  BY: "be_BY", // Belarus
  BE: "fr_BE", // Belgium (French) - this will overwrite 'nl_BE'
  BZ: "en_BZ", // Belize
  BO: "es_BO", // Bolivia
  BA: "bs_BA", // Bosnia and Herzegovina
  BR: "pt_BR", // Brazil
  BG: "bg_BG", // Bulgaria
  CA: "fr_CA", // Canada (French) - this will overwrite 'en_CA'
  CL: "es_CL", // Chile
  CN: "zh_CN", // China
  CO: "es_CO", // Colombia
  CR: "es_CR", // Costa Rica
  HR: "hr_HR", // Croatia
  CU: "es_CU", // Cuba
  CY: "el_CY", // Cyprus (Greek)
  CZ: "cs_CZ", // Czech Republic
  DK: "da_DK", // Denmark
  DO: "es_DO", // Dominican Republic
  EC: "es_EC", // Ecuador
  EG: "ar_EG", // Egypt
  SV: "es_SV", // El Salvador
  EE: "et_EE", // Estonia
  FI: "fi_FI", // Finland
  FR: "fr_FR", // France
  GE: "ka_GE", // Georgia
  DE: "de_DE", // Germany
  GR: "el_GR", // Greece
  GT: "es_GT", // Guatemala
  HN: "es_HN", // Honduras
  HK: "zh_HK", // Hong Kong
  HU: "hu_HU", // Hungary
  IS: "is_IS", // Iceland
  IN: "hi_IN", // India (Hindi)
  ID: "id_ID", // Indonesia
  IR: "fa_IR", // Iran
  IQ: "ar_IQ", // Iraq
  IE: "en_IE", // Ireland
  IL: "he_IL", // Israel
  IT: "it_IT", // Italy
  JM: "en_JM", // Jamaica
  JP: "ja_JP", // Japan
  JO: "ar_JO", // Jordan
  KZ: "kk_KZ", // Kazakhstan
  KE: "sw_KE", // Kenya
  KP: "ko_KP", // North Korea
  KR: "ko_KR", // South Korea
  KW: "ar_KW", // Kuwait
  KG: "ky_KG", // Kyrgyzstan
  LA: "lo_LA", // Laos
  LV: "lv_LV", // Latvia
  LB: "ar_LB", // Lebanon
  LY: "ar_LY", // Libya
  LT: "lt_LT", // Lithuania
  LU: "lb_LU", // Luxembourg
  MO: "zh_MO", // Macau
  MK: "mk_MK", // Macedonia
  MY: "ms_MY", // Malaysia
  MT: "mt_MT", // Malta
  MX: "es_MX", // Mexico
  MD: "ro_MD", // Moldova
  MC: "fr_MC", // Monaco
  MN: "mn_MN", // Mongolia
  ME: "sr_ME", // Montenegro
  MA: "ar_MA", // Morocco
  NL: "nl_NL", // Netherlands
  NZ: "en_NZ", // New Zealand
  NI: "es_NI", // Nicaragua
  NG: "en_NG", // Nigeria
  NO: "no_NO", // Norway
  OM: "ar_OM", // Oman
  PK: "ur_PK", // Pakistan
  PA: "es_PA", // Panama
  PY: "es_PY", // Paraguay
  PE: "es_PE", // Peru
  PH: "en_PH", // Philippines
  PL: "pl_PL", // Poland
  PT: "pt_PT", // Portugal
  QA: "ar_QA", // Qatar
  RO: "ro_RO", // Romania
  RU: "ru_RU", // Russia
  SA: "ar_SA", // Saudi Arabia
  RS: "sr_RS", // Serbia
  SG: "en_SG", // Singapore
  SK: "sk_SK", // Slovakia
  SI: "sl_SI", // Slovenia
  ZA: "en_ZA", // South Africa
  ES: "es_ES", // Spain
  LK: "si_LK", // Sri Lanka
  SE: "sv_SE", // Sweden
  CH: "fr_CH", // Switzerland (French) - this will overwrite 'de_CH'
  SY: "ar_SY", // Syria
  TW: "zh_TW", // Taiwan
  TH: "th_TH", // Thailand
  TN: "ar_TN", // Tunisia
  TR: "tr_TR", // Turkey
  UA: "uk_UA", // Ukraine
  AE: "ar_AE", // United Arab Emirates
  GB: "en_GB", // United Kingdom
  US: "en_US", // United States
  UY: "es_UY", // Uruguay
  UZ: "uz_UZ", // Uzbekistan
  VE: "es_VE", // Venezuela
  VN: "vi_VN", // Vietnam
  YE: "ar_YE", // Yemen
  ZW: "en_ZW", // Zimbabwe
};
