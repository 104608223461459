import React, { useCallback, useEffect, useState } from "react";
// @Mui
import {
  Avatar,
  Box,
  Button,
  Link,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
//
import OfferPopUp from "./OfferPopUp";
//__api__
import {
  fetchAppDataRequest,
  fetchGeneralPayoutRequest,
  fetchPublisherRequest,
} from "src/__apis__/apps";
//react-router-dom
import { useNavigate, useParams } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
//assets
import defultFusion from "../../assets/defultFusion.webp";

// -----------------------------------------------------------------------------------

function SurveyCard({ survey, openPopup }) {
  const theme = useTheme();
  const navigate = useNavigate();

  const { publisher_id, uid, app_id } = useParams();
  const [generalPayout, setGeneralPayout] = useState();
  const [finalPrice, setFinalPrice] = useState();
  const [appData, setAppData] = useState([]);
  const [publisherData, setPublisherData] = useState([]);

  const fetchGeneralPayoutData = useCallback(async () => {
    await fetchGeneralPayoutRequest()
      .then((response) => {
        setGeneralPayout(response[0]);
        console.log("general", response);
      })
      .catch((error) => {
        console.log("Failed get general payout", error);
      });
  }, []);

  const fetchPublisherData = useCallback(async () => {
    if (publisher_id) {
      await fetchPublisherRequest(publisher_id)
        .then((response) => {
          setPublisherData(response);
          // console.log(response);
        })
        .catch((error) => {
          console.log("Failed get publisher data", error);
          navigate("*");
        });
    }
  }, [publisher_id]);

  const fetchAppData = useCallback(async () => {
    await fetchAppDataRequest(app_id)
      .then((response) => {
        setAppData(response);
        console.log("res", response);
        if (response?.length == 0) {
          navigate("*");
        }
      })
      .catch((error) => {
        console.log("Failed get app data", error);
        navigate("*");
      });
  }, [app_id]);

  useEffect(() => {
    if (publisherData.special_payout_percentage === 0) {
      setFinalPrice(
        (parseFloat(survey.cpi) *
          appData[0]?.currency_multiplier *
          parseFloat(generalPayout?.general_payout_percentage)) /
          100
      );
    } else {
      setFinalPrice(
        (parseFloat(survey.cpi) *
          appData[0]?.currency_multiplier *
          parseFloat(publisherData?.special_payout_percentage)) /
          100
      );
    }
  }, [publisherData, generalPayout, survey, appData]);

  useEffect(() => {
    fetchAppData();
    fetchPublisherData();
    fetchGeneralPayoutData();
  }, []);

  return (
    <Box>
      <Paper sx={{ p: 2, border: `1px solid ${theme.palette.grey[500]}` }}>
        <Stack direction="row" alignItems="flex-start" gap={2}>
          <Avatar
            variant="rounded"
            src={defultFusion}
            sx={{ width: 70, height: 70 }}
          />
          <Stack px={3}>
            {/* <Typography
              sx={{
                whiteSpace: "normal", // Allows text to wrap
                overflow: "hidden",
                width: "100%", // Adjusts width to use 100% of the available space in the stack
              }}
              variant="h6"
            >
              {survey.surveyId}
            </Typography> */}
            <Typography
              sx={{
                display: "-webkit-box",
                overflow: "hidden",
                textOverflow: "ellipsis",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2, // Example: Limit to 3 lines
                width: "100%", // Ensure the typography component uses the full width available
              }}
            >
              {`${survey.estimatedLoi} min`}
            </Typography>
            <Box>
              <Button
                LinkComponent={Link}
                // href={survey.entryLink}
                onClick={() =>
                  navigate("/redirect", { state: { link: survey.entryLink } })
                }
                target="__blank"
                sx={{ mt: 1, width: "100%" }}
                variant="contained"
              >
                {`${finalPrice?.toFixed(2)} ${appData[0]?.currency_name}`}
              </Button>
            </Box>
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
}

export default SurveyCard;
