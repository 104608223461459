import React, { useEffect } from "react";
//MUI
import { Box, Typography } from "@mui/material";
//lottie
import Lottie from "lottie-react";
//react-router-dom
import { useLocation, useNavigate } from "react-router-dom";
//assets
import offerwallLogo from "../../assets/offerwallLogo.png";
import loadingAnimation from "src/assets/loading.json";

function RedirectPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const link = location.state?.link;

  console.log("link", link);

  useEffect(() => {
    const timer = setTimeout(() => {
      window.open(link, "_blank");
      navigate(-1);
    }, 2000); // 2-second delay

    return () => clearTimeout(timer);
  }, [link, navigate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate(-1);
    }, 2500); // 2.5-second delay
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Box component="img" src={offerwallLogo} />
      <Lottie animationData={loadingAnimation} style={{ width: 300 }} />
      <Typography variant="h3" sx={{ color: "#0079FF" }}>
        Loading...
      </Typography>
    </Box>
  );
}

export default RedirectPage;
