//MUI
import { Box, Typography } from "@mui/material";
//lottie
import Lottie from "lottie-react";
//assets
import notFound from "../assets/not-found.json";

export default function NotFoundPage() {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h3" paragraph>
        Sorry, page not found!
      </Typography>
      <Typography
        sx={{ color: "text.secondary", maxWidth: 600, textAlign: "center" }}
      >
        Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve
        mistyped the URL? Be sure to check your spelling.
      </Typography>
      <Lottie animationData={notFound} style={{ width: 320 }} />
    </Box>
  );
}
