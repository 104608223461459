import axiosInstance from "./axios";

export const offerWallObjectsFetcher = async (page = 1, variant) =>
  axiosInstance
    .get(`/offers/offer-wall-handler?page=${page}`, {
      params: { variant: variant },
    })
    .then((response) => response.data);

export const offerClickRequest = async (publisher_id) =>
  axiosInstance
    .post(`/publishers/offer-click`, {
      publisher_id: publisher_id,
    })
    .then((response) => response.data);
