import React from "react";
//mui
import { Box } from "@mui/material";
// theme
import ThemeProvider from "./theme";
// components
import { ProgressBarStyle } from "./components/ProgressBar";
import Alert from "./components/Alert";
import OfferWall from "./components/offerWall/OfferWall";
//recoil
import { RecoilRoot } from "recoil";
//react-router-dom
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
//pages
import NotFoundPage from "./components/NotFoundPage";
import RedirectPage from "./components/offerWall/RedirectPage";

function App() {
  return (
    <Box>
      <RecoilRoot>
        <ThemeProvider>
          <Router>
            <Routes>
              <Route
                path="/wall/:publisher_id/:uid/:app_id"
                element={<OfferWall />}
              />
              <Route path="*" element={<NotFoundPage />} />
              <Route path="/redirect" element={<RedirectPage />} />
            </Routes>
          </Router>
          <ProgressBarStyle />
          <Alert />
        </ThemeProvider>
      </RecoilRoot>
    </Box>
  );
}

export default App;
