import React, { useCallback, useEffect, useState } from "react";
// @Mui
import {
  Avatar,
  Box,
  Button,
  Link,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
//
import OfferPopUp from "./OfferPopUp";
//__api__
import { fetchAppDataRequest, fetchPublisherRequest } from "src/__apis__/apps";
//react-router-dom
import { useNavigate, useParams } from "react-router-dom";

// -----------------------------------------------------------------------------------

function OfferCard({ offer, openPopup }) {
  const theme = useTheme();
  const navigate = useNavigate();

  const { publisher_id, uid, app_id } = useParams();

  const [queryParams, setQueryParams] = useState({});
  const [offerPopUp, triggerOfferPopUp] = useState(false);
  const [appData, setAppData] = useState([]);
  const [publisherData, setPublisherData] = useState([]);
  const [finalPrice, setFinalPrice] = useState(0);
  const [finalEventPrice, setFinalEventPrice] = useState(0);

  const {
    name,
    description,
    preview_avatar,
    payout,
    publishers_payout,
    currency,
    cta_url,
    events,
    source,
  } = offer;

  const fetchPublisherData = useCallback(async () => {
    if (publisher_id) {
      await fetchPublisherRequest(publisher_id)
        .then((response) => {
          setPublisherData(response);
          // console.log(response);
        })
        .catch((error) => {
          console.log("Failed get publisher data", error);
          navigate("*");
        });
    }
  }, [publisher_id]);

  const fetchAppData = useCallback(async () => {
    await fetchAppDataRequest(app_id)
      .then((response) => {
        setAppData(response);
        console.log("res", response);
        if (response?.length == 0) {
          navigate("*");
        }
      })
      .catch((error) => {
        console.log("Failed get app data", error);
        navigate("*");
      });
  }, [app_id]);

  let totalEventPayout = 0;

  useEffect(() => {
    fetchAppData();
    fetchPublisherData();
  }, []);

  useEffect(() => {
    if (publisherData.special_payout_percentage === 0) {
      setFinalPrice(publishers_payout * appData[0]?.currency_multiplier);
      if (events) {
        events.map((event) => {
          totalEventPayout +=
            event?.publishers_payout * appData[0]?.currency_multiplier;
        });
      }
      setFinalEventPrice(totalEventPayout);
    } else {
      setFinalPrice(
        payout *
          (publisherData.special_payout_percentage / 100) *
          appData[0]?.currency_multiplier
      );
      if (events) {
        events.map((event) => {
          totalEventPayout +=
            event?.payout *
            (publisherData.special_payout_percentage / 100) *
            appData[0]?.currency_multiplier;
        });
      }
      setFinalEventPrice(totalEventPayout);
    }
  }, [publishers_payout, publisherData, appData, events]);

  return (
    <Box>
      <Paper sx={{ p: 2, border: `1px solid ${theme.palette.grey[500]}` }}>
        <Stack direction="row" alignItems="flex-start" gap={2}>
          <Avatar
            variant="rounded"
            src={preview_avatar}
            sx={{ width: 70, height: 70 }}
          />
          <Stack px={3}>
            <Typography
              sx={{
                whiteSpace: "normal", // Allows text to wrap
                overflow: "hidden",
                width: "100%", // Adjusts width to use 100% of the available space in the stack
              }}
              variant="h6"
            >
              {name}
            </Typography>
            <Typography
              sx={{
                display: "-webkit-box",
                overflow: "hidden",
                textOverflow: "ellipsis",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2, // Example: Limit to 3 lines
                width: "100%", // Ensure the typography component uses the full width available
              }}
            >
              {description}
            </Typography>
            <Box>
              <Button
                LinkComponent={Link}
                onClick={() => triggerOfferPopUp(true)}
                target="__blank"
                sx={{ mt: 1, width: "100%" }}
                variant="contained"
              >
                {publishers_payout && payout
                  ? `${finalPrice?.toFixed(2)} ${appData[0]?.currency_name}`
                  : `${finalEventPrice?.toFixed(2)} ${
                      appData[0]?.currency_name
                    }`}
              </Button>
            </Box>
          </Stack>
        </Stack>
      </Paper>
      <OfferPopUp
        isTriggered={offerPopUp}
        closeHandler={() => triggerOfferPopUp(false)}
        offer={offer}
        appData={appData}
        special_payout_percentage={publisherData?.special_payout_percentage}
      />
    </Box>
  );
}

export default OfferCard;
