import axios from "axios";

// export const userIpRegionFetcher = async () => axios.get('https://ipapi.co/json/').then((response) => response.data);

export const userIpRegionFetcher = async () => {
  const response = await axios.get(
    "https://geoip.maxmind.com/geoip/v2.1/city/me"
  );
  return response.data;
};
