import React, { useCallback, useEffect, useState } from "react";
// lottie-react
import Lottie from "lottie-react";
// recoil
import { useRecoilValue, useSetRecoilState } from "recoil";
import userIpRegionAtom from "src/recoil/atoms/userIpRegionAtom";
// @mui
import {
  Box,
  Container,
  Grid,
  Typography,
  Pagination,
  Stack,
} from "@mui/material";
// __apis__
import { offerWallObjectsFetcher } from "src/__apis__/offerWall";
import { userIpRegionFetcher } from "src/__apis__/userIpRegion";
// atoms
import alertAtom from "src/recoil/atoms/alert";
// logo
import logo from "../../logo.svg";
// assets
import loadingAnimation from "src/assets/loading.json";
import noDataAnimation from "src/assets/no-data.json";
//
import SearchOfferWall from "./SearchOfferWall";
import OfferWallNavigations from "./OfferWallNavigations";
import OfferCard from "./OfferCard";
import { countryToLang, fetchFusionSurveyRequest } from "src/__apis__/fusion";
import SurveyCard from "./SurveyCard";
import { encryptRequest } from "src/__apis__/apps";
import { useParams } from "react-router-dom";

// ---------------------------------------------------------------------------------------------------

function OfferWall() {
  const triggerAlert = useSetRecoilState(alertAtom);

  const setUserIpRegion = useSetRecoilState(userIpRegionAtom);

  const userRegion = useRecoilValue(userIpRegionAtom);
  const { publisher_id, uid, app_id } = useParams();

  const [activeTab, setActiveTab] = useState("offer");
  const [encryptedData, setEncryptedData] = useState();
  const [fusionrespondentIdData, setFusionrespondentIdData] = useState();
  const [loadingData, setIsLoadingData] = useState(false);
  const [offerWallData, setOfferWallData] = useState([]);
  const [surveyData, setSurveyData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const page_size = 10; // Align with your backend setting

  const encryptData = useCallback(async () => {
    await encryptRequest(app_id, uid, publisher_id)
      .then((response) => {
        setEncryptedData(response.data);
        setFusionrespondentIdData(response.fusion_respondent_id);
      })
      .catch((error) => {
        console.log("Failed encrypt data", error);
      });
  }, [app_id, uid]);

  const fetchFusionSurveyData = useCallback(async () => {
    fetchFusionSurveyRequest(
      encryptedData,
      fusionrespondentIdData,
      countryToLang[userRegion]
    )
      .then((response) => {
        setSurveyData(response);
        console.log("survey", response);
      })
      .catch((error) => {
        console.log("Error fetching fusion survey", error);
      });
  }, [encryptedData, userRegion, fusionrespondentIdData]);

  const fetchUserIpRegion = useCallback(async () => {
    userIpRegionFetcher()
      .then((response) => {
        setUserIpRegion(response.country.iso_code);
      })
      .catch((error) => {
        console.log("Error fetching user region", error);
        setUserIpRegion(null);
      });
  }, [setUserIpRegion]);

  const handleTabChange = useCallback(
    (tabValue) => {
      setActiveTab(tabValue);
    },
    [setActiveTab]
  );

  const fetchOfferWallObjects = useCallback(async () => {
    setIsLoadingData(true);
    await offerWallObjectsFetcher(currentPage, activeTab)
      .then((response) => {
        setOfferWallData(response.results);
        console.log("data", response);
        setTotalPages(Math.ceil(response.count / page_size)); // Compute total pages
      })
      .catch((error) => {
        console.log("Failed loading offer wall data", error);
        triggerAlert({
          triggered: true,
          type: "error",
          message: "Failed loading data at the moment, try again later.",
        });
      });
    setIsLoadingData(false);
  }, [currentPage, triggerAlert, activeTab]);

  useEffect(() => {
    fetchOfferWallObjects();
  }, [currentPage, fetchOfferWallObjects, activeTab]);

  useEffect(() => {
    fetchUserIpRegion();
  }, []);

  useEffect(() => {
    encryptData();
  }, [app_id, publisher_id, uid]);

  useEffect(() => {
    fetchFusionSurveyData();
  }, [encryptedData, userRegion]);

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };

  //filtring with country
  //-------------------------------------------
  // useEffect(() => {
  //   let regionOfferWallData = [];

  //   offerWallData?.map((offer) => {
  //     if (offer?.countries?.includes(userRegion)) {
  //       regionOfferWallData.push(offer);
  //     }
  //   });

  //   setOfferWallData(regionOfferWallData);
  // }, [offerWallData, userRegion]);

  return (
    <Box sx={{ pt: 6 }}>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box
              sx={{ width: "100%", justifyContent: "center", display: "flex" }}
            >
              <Box component="img" src={logo} />
            </Box>
          </Grid>
          {/*  <Grid item xs={12}>
            <SearchOfferWall />
          </Grid> */}
          <Grid item xs={12}>
            <OfferWallNavigations
              handleTabChange={handleTabChange}
              activeTab={activeTab}
            />
          </Grid>
          {
            //fusion
            activeTab == "survey" && (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {surveyData?.surveys?.map((survey, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <SurveyCard survey={survey} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )
          }
          {offerWallData.length === 0 && surveyData?.surveys?.length == 0 && (
            <Grid item xs={12}>
              <Stack justifyContent="center" alignItems="center">
                <Lottie
                  animationData={noDataAnimation}
                  style={{ width: 320 }}
                />
                <Typography variant="h4" color="primary">
                  No Data Found
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ display: "inline-flex", alignItems: "center" }}
                >
                  We are currently have no data for{" "}
                  <Typography
                    variant="subtitle1"
                    sx={{ ml: 1, textDecoration: "underline" }}
                    color="primary"
                  >
                    {activeTab}s
                  </Typography>
                  , comeback later
                </Typography>
              </Stack>
            </Grid>
          )}
          <Grid item xs={12}>
            {loadingData ? (
              <Stack justifyContent="center" alignItems="center">
                <Lottie animationData={loadingAnimation} />
                <Typography variant="h4" color="primary">
                  Loading Please Wait.
                </Typography>
                <Typography variant="subtitle1">
                  Please Be Patient While We Are Loading Data From Our Servers.
                </Typography>
              </Stack>
            ) : (
              <Grid container spacing={2}>
                {offerWallData.map((offer, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <OfferCard offer={offer} />
                  </Grid>
                ))}
              </Grid>
            )}
            {totalPages > 1 && (
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handleChangePage}
                color="primary"
                sx={{ mt: 2, mb: 2, display: "flex", justifyContent: "center" }}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default OfferWall;
