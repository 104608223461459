import axios from "axios";

// http://127.0.0.1:5000  // https://adzirc-dff92f77804b.herokuapp.com

export const mainUrl = "https://adzirc-dff92f77804b.herokuapp.com";

const axiosInstance = axios.create({
  baseURL: mainUrl,
  timeout: 100000,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

export default axiosInstance;
