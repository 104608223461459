import React from "react";
// @Mui
import { Box, Button, ButtonGroup } from "@mui/material";

// -----------------------------------------------------

function OfferWallNavigations({ handleTabChange, activeTab }) {
  return (
    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <ButtonGroup fullWidth sx={{ width: "70%" }}>
        <Button
          size="large"
          onClick={() => handleTabChange("survey")}
          variant={activeTab === "survey" ? "contained" : "outlined"}
        >
          Surveys
        </Button>
        <Button
          size="large"
          onClick={() => handleTabChange("offer")}
          variant={activeTab === "offer" ? "contained" : "outlined"}
        >
          Offers
        </Button>
        <Button
          size="large"
          onClick={() => handleTabChange("game")}
          variant={activeTab === "game" ? "contained" : "outlined"}
        >
          Games
        </Button>
      </ButtonGroup>
    </Box>
  );
}

export default OfferWallNavigations;
