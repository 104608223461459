import axiosInstance from "./axios";

export const fetchAppDataRequest = async (appId) =>
  axiosInstance
    .get("publisher-apps/publishers-apps-offrwall-handler", {
      params: { appId },
    })
    .then((response) => response.data);

export const fetchPublisherRequest = async (publisher_id) =>
  axiosInstance
    .get("publishers/publisher-offrwall-handler", {
      params: { publisher_id },
    })
    .then((response) => response.data);

export const fetchGeneralPayoutRequest = async () =>
  axiosInstance
    .get("publishers/fetch-general-payout-percentage")
    .then((response) => response.data);

export const encryptRequest = async (appId, uId, publisherId) =>
  axiosInstance
    .get("publishers/encrypt-data", {
      params: { appId, uId, publisherId },
    })
    .then((response) => response.data);
